import { FormControl } from "@cev/design-components";
import { AuthenticationApi } from "@eduthings/app-scripts/generated/apis/AuthenticationApi";
import { DistrictApi } from "@eduthings/app-scripts/generated/apis/DistrictApi";
import type { District } from "@eduthings/app-scripts/generated/models/District";
import { action, computed, observable } from "mobx";

export class DistrictSwitcherUIViewModel {
    selectFirstDistrict() {
        const firstDistrict = this.filteredDistricts[0];
        if (firstDistrict) {
            this.newDistrictId = firstDistrict.districtId;
            this.newDistrictName = firstDistrict.name || undefined;
            this.isProcessingDistrictSwitch = true;
            window.location.href = `/district_switcher/${this.newDistrictId}/?return_to=${window.location.href}`;
        }
    }
    constructor() {
        this.initialize();
    }

    @observable
    accessor districts: District[] = [];

    @observable
    accessor districtId = 0;

    @observable
    accessor districtName: string | undefined;

    @observable
    accessor newDistrictId = 0;

    @observable
    accessor newDistrictName: string | undefined;

    @observable
    accessor isVisible = false;

    @observable
    accessor isProcessingDistrictSwitch = false;

    @observable
    accessor isLoadingDistricts = false;

    @observable
    accessor searchTerm = "";

    @computed
    get switchDisabled() {
        return this.districtId == this.newDistrictId || this.newDistrictId === 0;
    }

    @computed
    get filteredDistricts() {
        return this.districts
            .filter((x) => {
                const isMatch = x.districtId != this.districtId && (!this.searchTerm || x.name?.toLowerCase().includes(this.searchTerm?.toLowerCase()) || !this.searchTerm || x.districtId?.toString().includes(this.searchTerm));
                return isMatch;
            })
            .sort((a, b) => {
                if ((a.name || "") < (b.name || "")) {
                    return -1;
                }
                if ((a.name || "") > (b.name || "")) {
                    return 1;
                }
                return 0;
            });
    }

    @action
    async openSwitchDistrictModal() {
        this.isVisible = true;
        await this.load();
    }

    @action
    cancelSwitchDistrict() {
        if (!this.isProcessingDistrictSwitch) {
            this.isVisible = false;
        }
    }

    @action
    filterByDistrictNameOrId(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

    @action
    async load() {
        this.isLoadingDistricts = true;

        // loading data for skeleton
        this.districts = [<District>{ districtId: 1000000, name: "The name of the District 1" }, <District>{ districtId: 2000000, name: "The name of the District 2" }];

        // load districts
        const districtClient = new DistrictApi();
        this.districts = await districtClient.getAll();
        this.districtName = this.districts.find((x) => x.districtId === this.districtId)?.name || "";

        this.isLoadingDistricts = false;
    }

    @action
    async initialize() {
        const client = new AuthenticationApi();
        const currentState = await client.get();
        if (currentState?.districtId) {
            const districtClient = new DistrictApi();
            const currentDistrict = await districtClient.get(currentState.districtId);
            this.districtName = currentDistrict.name || undefined;
        }
    }
}
