import { Button, Card, CardBody, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Spinner, Table } from "@cev/design-components";
import type { AuthContext } from "@eduthings/app-scripts/models/security/AuthContext";
import { DistrictSwitcherUIViewModel } from "@eduthings/app-scripts/viewModels/layout/DistrictSwitcherUIViewModel";
import { observer } from "mobx-react";
import { useMemo } from "react";

interface DistrictSwitcherUIProps {
    authContext: AuthContext;
}

export function render(props: DistrictSwitcherUIProps) {
    const viewModel = useMemo(() => {
        const vm = new DistrictSwitcherUIViewModel();
        vm.districtId = props.authContext?.state?.districtId ?? 0;
        return vm;
    }, [props.authContext?.state?.districtId]);

    return (
        <>
            {viewModel.districtName && (
                <Button
                    size="md"
                    onClick={async () => {
                        await viewModel.openSwitchDistrictModal();
                    }}
                >
                    {viewModel.districtName}
                </Button>
            )}
            <Modal
                show={viewModel.isVisible}
                onHide={() => {
                    viewModel.cancelSwitchDistrict();
                }}
                className="modal-xl"
            >
                <ModalHeader closeButton={!viewModel.isProcessingDistrictSwitch}>
                    <ModalTitle>Switch District</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className="layoutv3" style={{ width: "100%", height: "100%" }}>
                        <div className="content">
                            <div className="overflow-auto vstack gap-3">
                                <Card>
                                    <CardBody>
                                        <div className="justify-content-stretch flex-wrap vstack gap-2">
                                            <div className="hstack gap-3">
                                                {/* search/filter */}
                                                {!viewModel.isLoadingDistricts && (
                                                    <FormControl
                                                        type="text"
                                                        placeholder="Enter district name or ID..."
                                                        autoFocus
                                                        className="mb-2"
                                                        disabled={viewModel.isProcessingDistrictSwitch}
                                                        onChange={(e) => {
                                                            viewModel.filterByDistrictNameOrId(e.target.value);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                viewModel.selectFirstDistrict();
                                                            }
                                                        }}
                                                    ></FormControl>
                                                )}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="overflow-auto full-screen-wrapper">
                                    <Table bordered={false} hover={false} responsive={true} striped={true} size="sm" className={viewModel.isLoadingDistricts ? "table-default skeleton-loader skeleton-loader-visible" : "table-default skeleton-loader"}>
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewModel.filteredDistricts.map((district) => (
                                                <tr key={district.districtId}>
                                                    <td>
                                                        <span className="skeleton-loader-item">{district.districtId}</span>
                                                    </td>
                                                    <td>
                                                        <span className="skeleton-loader-item">{district.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="skeleton-loader-item">
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                href={`/district_switcher/${district.districtId}/?return_to=${window.location.href}`}
                                                                className={viewModel.isProcessingDistrictSwitch ? "disabled" : ""}
                                                                onClick={() => {
                                                                    viewModel.isProcessingDistrictSwitch = true;
                                                                }}
                                                            >
                                                                Switch
                                                            </Button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            {!viewModel.filteredDistricts.length && (
                                                <tr>
                                                    <td colSpan={3}>
                                                        <Card>
                                                            <CardBody className="text-center">No results found</CardBody>
                                                        </Card>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {viewModel.isProcessingDistrictSwitch && (
                        <div>
                            <Spinner size="sm" /> <span>Switching Districts...</span>
                        </div>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default observer(render);
